<template>
  <div class="onboarding-form">
    <div class="pb-5 pt-lg-0 pt-3">
      <div class="row">
        <div class="pb-5 pt-lg-0 pt-5 animate__animated animate__backInUp">
          <h1><i class="la la-check-circle text-success fa-3x"></i></h1>
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
            Administrator Invitation Sent!
          </h3>
          <p class="text-success font-weight-bold font-size-h4">
            An email invitation has been sent to the administrator's email
            address you provided.<br />
            We will let you know when they accept your invitation.
          </p>
        </div>
      </div>
    </div>

    <div class="pb-lg-0 pb-5">
      <router-link to="/dashboard" v-slot="{ navigate }">
        <button
          type="button"
          class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
          @click="navigate"
        >
          Goto My Dashboard<i class="fas fa-arrow-right ml-2"></i>
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      isAutomatic: true,
      loading: false
    };
  },
  components: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {},
  methods: {}
};
</script>
